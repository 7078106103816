import { useProjects } from '@/hooks/use-projects';
import { DashboardContainer } from '@/components/dashboard/DashboardContainer';
import { LoadingScreen } from '@/components/LoadingScreen';

export function DashboardPage() {
  const { projects, loading, error, refreshProjects } = useProjects();

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <DashboardContainer 
      projects={projects}
      loading={loading}
      error={error}
      onRefresh={refreshProjects}
    />
  );
}
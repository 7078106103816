import { cn } from '@/lib/utils';
import { ProjectWithDates, ViewMode } from './types';
import { GanttRow } from './GanttRow';
import { TimeGrid } from './TimeGrid';
import { getHeaderTitle, getTimeUnits } from '@/lib/date-utils';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';

interface GanttGridProps {
  daysToShow: Date[];
  currentDate: Date;
  projects: ProjectWithDates[];
  viewMode: ViewMode;
  onProjectClick: (project: ProjectWithDates) => void;
}

export function GanttGrid({ daysToShow, currentDate, projects, viewMode, onProjectClick }: GanttGridProps) {
  // Sort projects: active first, then by urgency level (highest to lowest), then undefined dates last
  const sortedProjects = [...projects].sort((a, b) => {
    // Projects with undefined dates go last
    if (!a.endDate && b.endDate) return 1;
    if (a.endDate && !b.endDate) return -1;
    if (!a.endDate && !b.endDate) return b.urgencyLevel - a.urgencyLevel;
    
    // Then sort by visibility and urgency (highest to lowest)
    if (a.isVisible && !b.isVisible) return -1;
    if (!a.isVisible && b.isVisible) return 1;
    return b.urgencyLevel - a.urgencyLevel;
  });

  const timeUnits = getTimeUnits(currentDate, viewMode);

  const getMinWidth = () => {
    switch (viewMode) {
      case 'Year':
        return 'min-w-[1440px]'; // 120px per month
      case 'Quarter':
        return 'min-w-[1440px]'; // Reduced from 1800px to 1440px
      case 'Month':
        return 'min-w-[2100px]'; // ~70px per day
      case 'Week':
        return 'min-w-[1680px]'; // 240px per day
      default:
        return 'min-w-[1440px]';
    }
  };

  return (
    <ScrollArea className="w-full border rounded-lg bg-background">
      <div className={cn("relative", getMinWidth())}>
        <div className="grid grid-cols-[250px_1fr] gap-4">
          {/* Fixed left column */}
          <div className="sticky left-0 z-20 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <div className="h-full flex items-center px-4 font-medium text-foreground">
              {getHeaderTitle(currentDate, viewMode)}
            </div>
          </div>

          {/* Time grid */}
          <TimeGrid
            timeUnits={timeUnits}
            daysToShow={daysToShow}
            currentDate={currentDate}
            viewMode={viewMode}
          />
        </div>

        {/* Project rows */}
        <div className="mt-4 space-y-2">
          {sortedProjects.map((project) => (
            <div
              key={project.id}
              className={cn(
                "transition-all duration-200",
                !project.isVisible && "opacity-50"
              )}
            >
              <GanttRow
                project={project}
                daysToShow={daysToShow}
                onClick={() => onProjectClick(project)}
                viewMode={viewMode}
              />
            </div>
          ))}
        </div>
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}
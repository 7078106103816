import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Project } from '@/types/project';
import { projectSchema } from '@/lib/data-validation';
import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Loader2 } from 'lucide-react';
import { ProjectBasicInfo } from './sections/ProjectBasicInfo';
import { ProjectWorkload } from './sections/ProjectWorkload';
import { ProjectTeam } from './sections/ProjectTeam';

interface ProjectFormProps {
  project?: Project | null;
  onSubmit: (data: Omit<Project, 'id' | 'timeRemaining'>) => Promise<void>;
  onCancel: () => void;
  isLoading?: boolean;
}

export function ProjectForm({ project, onSubmit, onCancel, isLoading }: ProjectFormProps) {
  const form = useForm({
    resolver: zodResolver(projectSchema),
    defaultValues: project ? {
      name: project.name,
      logo: project.logo,
      urgencyLevel: project.urgencyLevel,
      progress: project.progress,
      deadline: project.deadline,
      workType: project.workType,
      description: project.description,
      technology: project.technology,
      workload: project.workload,
      projectManager: project.projectManager,
      team: project.team.map(member => ({
        name: member.name,
        role: member.role,
        isLead: member.isLead,
        isTechLead: member.isTechLead,
        avatar: member.avatar
      }))
    } : {
      progress: 0,
      workload: { estimated: 0, consumed: 0 },
      team: []
    }
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <ProjectBasicInfo control={form.control} />
        <ProjectWorkload control={form.control} />
        <ProjectTeam control={form.control} />

        <div className="flex justify-end gap-4">
          <Button type="button" variant="outline" onClick={onCancel}>
            Annuler
          </Button>
          <Button type="submit" disabled={isLoading}>
            {isLoading && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
            {project ? 'Mettre à jour' : 'Créer'}
          </Button>
        </div>
      </form>
    </Form>
  );
}
import { format, isSameMonth, isToday, isWeekend } from 'date-fns';
import { fr } from 'date-fns/locale';
import { cn } from '@/lib/utils';
import { ViewMode } from './types';
import { TimeUnit } from '@/lib/date-utils';

interface TimeGridProps {
  timeUnits: TimeUnit[];
  daysToShow: Date[];
  currentDate: Date;
  viewMode: ViewMode;
}

export function TimeGrid({ timeUnits, daysToShow, currentDate, viewMode }: TimeGridProps) {
  const getDayWidth = () => {
    switch (viewMode) {
      case 'Year':
        return 'min-w-[120px]'; // 120px per month
      case 'Quarter':
        return 'min-w-[16px]';  // Reduced from 20px to 16px per day
      case 'Month':
        return 'min-w-[70px]';  // ~70px per day
      case 'Week':
        return 'min-w-[240px]'; // 240px per day
      default:
        return 'min-w-[40px]';
    }
  };

  const getDayBackground = (date: Date, index: number) => {
    if (isToday(date)) return 'bg-primary/10';
    if (isWeekend(date)) return 'bg-muted/50';
    if (!isSameMonth(date, currentDate)) return 'bg-muted/30';
    // Add alternating background for groups of 5 days
    return Math.floor(index / 5) % 2 === 0 ? 'bg-muted/10' : 'bg-background';
  };

  return (
    <div className="relative">
      {/* Time units header */}
      <div className="grid grid-flow-col auto-cols-fr border-b">
        {timeUnits.map((unit, index) => (
          <div
            key={unit.start.toISOString()}
            className={cn(
              "text-center py-3 px-2 font-medium text-foreground border-r",
              index === 0 && "border-l"
            )}
            style={{ gridColumn: `span ${unit.days.length}` }}
          >
            {unit.label}
          </div>
        ))}
      </div>

      {/* Days grid */}
      {viewMode !== 'Year' && (
        <div className="grid grid-flow-col auto-cols-fr">
          {daysToShow.map((date, index) => (
            <div
              key={date.toISOString()}
              className={cn(
                getDayWidth(),
                "text-center py-2 px-1 text-sm border-r",
                index === 0 && "border-l",
                getDayBackground(date, index),
                isToday(date) && "font-bold text-primary",
                !isSameMonth(date, currentDate) && "text-muted-foreground"
              )}
            >
              {format(date, viewMode === 'Week' ? 'EEE d' : 'd', { locale: fr })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
import { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { LoginForm } from '@/components/auth/LoginForm';
import { DebugDialog } from '@/components/auth/DebugDialog';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';

export function LoginPage() {
  const { isAuthenticated, error } = useAuth();
  const location = useLocation();
  const locationError = location.state?.error;
  const [debugLogs, setDebugLogs] = useState<DebugInfo[]>([]);

  useEffect(() => {
    if (error || locationError) {
      addDebugLog('error', 'Erreur d\'authentification', { error, locationError });
    }
  }, [error, locationError]);

  const addDebugLog = (type: 'info' | 'error', message: string, data?: any) => {
    setDebugLogs(prev => [{
      timestamp: new Date().toISOString(),
      type,
      message,
      data
    }, ...prev]);
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="w-full max-w-md space-y-8">
        {(error || locationError) && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              {error || locationError}
            </AlertDescription>
          </Alert>
        )}

        <LoginForm onDebugLog={addDebugLog} />
        <DebugDialog logs={debugLogs} />
      </div>
    </div>
  );
}
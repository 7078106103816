import { Project } from '@/types/project';

export type SortOption = 'urgency' | 'deadline' | 'progress' | 'workload';
export type SortDirection = 'asc' | 'desc';

export interface SortConfig {
  option: SortOption;
  direction: SortDirection;
}

export function sortProjects(projects: Project[], config: SortConfig): Project[] {
  return [...projects].sort((a, b) => {
    const direction = config.direction === 'asc' ? 1 : -1;
    
    switch (config.option) {
      case 'urgency':
        return (b.urgencyLevel - a.urgencyLevel) * direction;
      case 'deadline': {
        const dateA = new Date(a.deadline.split('/').reverse().join('-'));
        const dateB = new Date(b.deadline.split('/').reverse().join('-'));
        return (dateB.getTime() - dateA.getTime()) * direction;
      }
      case 'progress':
        return (b.progress - a.progress) * direction;
      case 'workload':
        return (b.workload.estimated - a.workload.estimated) * direction;
      default:
        return 0;
    }
  });
}
import { useState } from 'react';
import { Project } from '@/types/project';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { AlertCircle, Loader2 } from 'lucide-react';
import { DashboardHeader } from './DashboardHeader';
import { DashboardFilters } from './DashboardFilters';
import { DashboardGrid } from './DashboardGrid';
import { ProjectDetailsDialog } from '../project/ProjectDetailsDialog';
import { ProjectDialog } from '../project/ProjectDialog';
import { filterProjects, getTechnologies } from '@/lib/filters';
import { sortProjects, SortConfig, SortOption } from '@/lib/sort';

interface DashboardContainerProps {
  projects: Project[];
  loading: boolean;
  error: string | null;
  onRefresh?: () => Promise<void>;
}

export function DashboardContainer({ projects, loading, error, onRefresh }: DashboardContainerProps) {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [selectedTechnology, setSelectedTechnology] = useState<string>('all');
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    option: 'urgency',
    direction: 'desc'
  });

  const technologies = getTechnologies(projects);
  const filteredProjects = filterProjects(projects, selectedTechnology);
  const sortedProjects = sortProjects(filteredProjects, sortConfig);

  const handleProjectClick = (project: Project) => {
    setSelectedProject(project);
    setShowDetailsDialog(true);
  };

  const handleEditClick = (project: Project) => {
    setSelectedProject(project);
    setShowEditDialog(true);
  };

  const handleCreateClick = () => {
    setSelectedProject(null);
    setShowCreateDialog(true);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[50vh] gap-8">
        <div className="flex items-center gap-2">
          <Loader2 className="w-6 h-6 animate-spin text-primary" />
          <span className="text-sm text-muted-foreground">Chargement des projets...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <DashboardHeader onCreateProject={handleCreateClick} />

      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <DashboardFilters
          selectedTechnology={selectedTechnology}
          technologies={technologies}
          sortConfig={sortConfig}
          projectCount={sortedProjects.length}
          onTechnologyChange={setSelectedTechnology}
          onSortChange={(option) => setSortConfig(prev => ({ ...prev, option }))}
          onSortDirectionChange={() => 
            setSortConfig(prev => ({
              ...prev,
              direction: prev.direction === 'asc' ? 'desc' : 'asc'
            }))
          }
        />
      </div>

      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <DashboardGrid
        projects={sortedProjects}
        onProjectClick={handleProjectClick}
        onProjectEdit={handleEditClick}
      />

      <ProjectDetailsDialog
        project={selectedProject}
        open={showDetailsDialog}
        onOpenChange={setShowDetailsDialog}
        onEdit={() => {
          setShowDetailsDialog(false);
          setShowEditDialog(true);
        }}
      />

      <ProjectDialog
        project={selectedProject}
        open={showEditDialog || showCreateDialog}
        onOpenChange={(open) => {
          if (!open) {
            setShowEditDialog(false);
            setShowCreateDialog(false);
          }
        }}
        onSuccess={onRefresh}
      />
    </div>
  );
}
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { LoadingScreen } from '@/components/LoadingScreen';

export function AuthCallback() {
  const navigate = useNavigate();
  const { isAuthenticated, error, handleAuthCallback } = useAuth();

  useEffect(() => {
    const processCallback = async () => {
      try {
        await handleAuthCallback();
        
        if (isAuthenticated) {
          navigate('/dashboard');
        } else if (error) {
          navigate('/auth/login', { state: { error } });
        }
      } catch (error) {
        console.error('Auth callback error:', error);
        navigate('/auth/login', { 
          state: { error: 'Une erreur est survenue lors de l\'authentification' }
        });
      }
    };

    processCallback();
  }, [navigate, isAuthenticated, error, handleAuthCallback]);

  return <LoadingScreen />;
}
import { useMemo } from 'react';
import { Project } from '@/types/project';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Users, Clock, AlertTriangle, Calendar } from 'lucide-react';
import { TechnologyDistribution } from './charts/TechnologyDistribution';
import { WorkloadByTechnology } from './charts/WorkloadByTechnology';
import { UrgencyDistribution } from './charts/UrgencyDistribution';
import { TeamSizeChart } from './charts/TeamSizeChart';

interface StatisticsDashboardProps {
  projects: Project[];
}

export function StatisticsDashboard({ projects }: StatisticsDashboardProps) {
  const stats = useMemo(() => {
    const techDistribution = projects.reduce((acc, project) => {
      acc[project.technology] = (acc[project.technology] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    const urgencyDistribution = projects.reduce((acc, project) => {
      acc[project.urgencyLevel] = (acc[project.urgencyLevel] || 0) + 1;
      return acc;
    }, {} as Record<number, number>);

    const workloadByTech = projects.reduce((acc, project) => {
      if (!acc[project.technology]) {
        acc[project.technology] = { estimated: 0, consumed: 0 };
      }
      acc[project.technology].estimated += project.workload.estimated;
      acc[project.technology].consumed += project.workload.consumed;
      return acc;
    }, {} as Record<string, { estimated: number; consumed: number }>);

    const teamSizeDistribution = projects.map(project => ({
      name: project.name,
      members: project.team.length,
      workload: project.workload.estimated
    }));

    return {
      techDistribution: Object.entries(techDistribution).map(([name, value]) => ({
        name,
        value
      })),
      urgencyDistribution: Object.entries(urgencyDistribution).map(([level, count]) => ({
        name: `Niveau ${level}`,
        count
      })),
      workloadByTech: Object.entries(workloadByTech).map(([tech, data]) => ({
        name: tech,
        estimated: data.estimated,
        consumed: data.consumed
      })),
      teamSizeDistribution: teamSizeDistribution.sort((a, b) => b.workload - a.workload).slice(0, 5)
    };
  }, [projects]);

  return (
    <div className="container mx-auto py-8 px-4">
      <div className="flex flex-col gap-8">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold text-foreground">Statistiques des Projets</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-foreground">
                <Users className="h-5 w-5" />
                Distribution des Technologies
              </CardTitle>
            </CardHeader>
            <CardContent>
              <TechnologyDistribution data={stats.techDistribution} />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-foreground">
                <Clock className="h-5 w-5" />
                Charge de travail par Technologie
              </CardTitle>
            </CardHeader>
            <CardContent>
              <WorkloadByTechnology data={stats.workloadByTech} />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-foreground">
                <AlertTriangle className="h-5 w-5" />
                Distribution des Niveaux d'Urgence
              </CardTitle>
            </CardHeader>
            <CardContent>
              <UrgencyDistribution data={stats.urgencyDistribution} />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-foreground">
                <Calendar className="h-5 w-5" />
                Top 5 - Taille des Équipes vs Charge
              </CardTitle>
            </CardHeader>
            <CardContent>
              <TeamSizeChart data={stats.teamSizeDistribution} />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
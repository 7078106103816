import { Edit } from 'lucide-react';
import { Project } from '@/types/project';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { CardHeader } from '@/components/ui/card';
import { PriorityIndicator } from './PriorityIndicator';
import { ProjectLogo } from './ProjectLogo';
import { getTechnologyColor } from '@/lib/project-utils';

interface ProjectHeaderProps {
  project: Project;
  onEdit: () => void;
}

export function ProjectHeader({ project, onEdit }: ProjectHeaderProps) {
  const isHighPriority = project.urgencyLevel >= 4;

  return (
    <CardHeader className="relative">
      <Button
        variant="ghost"
        size="icon"
        className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity z-10"
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
      >
        <Edit className="w-4 h-4" />
      </Button>

      <PriorityIndicator level={project.urgencyLevel} />
      
      <div className="flex items-center gap-4">
        <ProjectLogo project={project} />
        
        <div className="flex-1">
          <div className="flex flex-col gap-1">
            <h3 className="font-semibold text-lg">{project.name}</h3>
            <div className="flex items-center gap-2">
              <span className="text-sm text-muted-foreground">Chef de projet:</span>
              <span className="text-sm font-medium">{project.projectManager}</span>
            </div>
          </div>
          
          <div className="flex flex-col gap-2 mt-2">
            <div className="flex items-center gap-2">
              <Badge variant="secondary" className={getTechnologyColor(project.technology)}>
                {project.technology}
              </Badge>
              {isHighPriority && (
                <Badge variant="destructive" className="animate-[pulse_2s_ease-in-out_infinite]">
                  Urgent
                </Badge>
              )}
            </div>
            <Badge variant="outline" className="text-muted-foreground w-fit">
              {project.workType}
            </Badge>
          </div>
        </div>
      </div>
    </CardHeader>
  );
}
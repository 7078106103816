import { useState, useEffect } from 'react';
import { Project } from '@/types/project';
import { fetchProjects } from '@/lib/api';
import { useToast } from '@/hooks/use-toast';

export function useProjects() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    let mounted = true;

    const loadProjects = async () => {
      try {
        setError(null);
        const data = await fetchProjects();
        if (mounted) {
          setProjects(data);
        }
      } catch (error) {
        if (mounted) {
          const errorMessage = 'Impossible de charger les projets. Veuillez réessayer plus tard.';
          setError(errorMessage);
          toast({
            variant: "destructive",
            title: "Erreur",
            description: errorMessage
          });
          console.error('Error loading projects:', error);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    loadProjects();

    return () => {
      mounted = false;
    };
  }, [toast]);

  const refreshProjects = async () => {
    setLoading(true);
    try {
      const data = await fetchProjects();
      setProjects(data);
      toast({
        title: "Succès",
        description: "Les projets ont été mis à jour"
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: "Impossible de rafraîchir les projets"
      });
    } finally {
      setLoading(false);
    }
  };

  return { projects, loading, error, refreshProjects };
}
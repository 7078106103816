import { cn } from '@/lib/utils';
import { AlertCircle } from 'lucide-react';

interface PriorityIndicatorProps {
  level: number;
  className?: string;
}

export function PriorityIndicator({ level, className }: PriorityIndicatorProps) {
  const dots = Array.from({ length: 5 }, (_, index) => index + 1);
  const isMaxPriority = level === 5;
  
  return (
    <div className={cn(
      "absolute -top-4 right-4 flex items-center gap-1 p-3 rounded-xl bg-white shadow-lg",
      isMaxPriority && "bg-red-50",
      className
    )}>
      {isMaxPriority && (
        <AlertCircle className="w-5 h-5 text-red-500 animate-pulse mr-1" />
      )}
      <div className="flex items-center gap-1">
        {dots.map((dot) => (
          <div
            key={dot}
            className={cn(
              "rounded-full transition-all duration-300",
              dot <= level
                ? dot === 5
                  ? "w-4 h-4 bg-red-500 animate-[ping_2s_ease-in-out_infinite]"
                  : dot === 4
                  ? "w-3.5 h-3.5 bg-orange-500"
                  : dot === 3
                  ? "w-3 h-3 bg-yellow-500"
                  : dot === 2
                  ? "w-3 h-3 bg-blue-500"
                  : "w-3 h-3 bg-green-500"
                : "w-3 h-3 bg-gray-200"
            )}
          />
        ))}
      </div>
    </div>
  );
}
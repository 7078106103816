import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { chartColors, createTooltipProps } from '@/lib/chart-utils';
import { ChartContainer } from './ChartContainer';

interface TechnologyData {
  name: string;
  value: number;
}

interface TechnologyDistributionProps {
  data: TechnologyData[];
}

export function TechnologyDistribution({ data }: TechnologyDistributionProps) {
  return (
    <ChartContainer>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          label={({ name, value }) => `${name} (${value})`}
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
          ))}
        </Pie>
        <Tooltip {...createTooltipProps<TechnologyData>()} />
      </PieChart>
    </ChartContainer>
  );
}
import {
  format,
  startOfYear,
  endOfYear,
  startOfQuarter,
  endOfQuarter,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachDayOfInterval,
  differenceInDays,
  startOfDay,
  parse,
  isValid
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { ViewMode } from '@/components/gantt/types';

export interface TimeUnit {
  label: string;
  start: Date;
  end: Date;
  days: Date[];
  width: number;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export function calculateTimeRemaining(deadline: string): string {
  if (!deadline || deadline === '----') return 'Non défini';
  
  try {
    const parsedDate = parseDate(deadline);
    if (!parsedDate) return 'Date invalide';
    
    const now = new Date();
    const diffDays = differenceInDays(parsedDate, now);
    
    if (diffDays < 0) return 'Dépassé';
    if (diffDays === 0) return 'Aujourd\'hui';
    if (diffDays === 1) return 'Demain';
    return `${diffDays} jours`;
  } catch (error) {
    console.error('Error calculating time remaining:', error);
    return 'Date invalide';
  }
}

export function parseDate(dateString: string): Date | null {
  if (!dateString || dateString === '----') return null;
  
  try {
    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    return isValid(date) ? date : null;
  } catch {
    return null;
  }
}

export function formatDate(date: Date | null, formatString: string = 'dd/MM/yyyy'): string {
  if (!date || !isValid(date)) return '----';
  try {
    return format(date, formatString, { locale: fr });
  } catch {
    return '----';
  }
}

export function getDateRange(date: Date, viewMode: ViewMode): DateRange {
  const ranges: Record<ViewMode, () => DateRange> = {
    Year: () => ({
      start: startOfYear(date),
      end: endOfYear(date)
    }),
    Quarter: () => ({
      start: startOfQuarter(date),
      end: endOfQuarter(date)
    }),
    Month: () => ({
      start: startOfMonth(date),
      end: endOfMonth(date)
    }),
    Week: () => ({
      start: startOfWeek(date, { weekStartsOn: 1 }),
      end: endOfWeek(date, { weekStartsOn: 1 })
    })
  };

  return ranges[viewMode]();
}

export function getTimeUnits(currentDate: Date, viewMode: ViewMode): TimeUnit[] {
  const range = getDateRange(currentDate, viewMode);
  const totalDays = differenceInDays(range.end, range.start) + 1;

  const createTimeUnit = (start: Date, end: Date, label: string): TimeUnit => {
    const days = eachDayOfInterval({ start, end });
    return {
      label,
      start: startOfDay(start),
      end: startOfDay(end),
      days,
      width: (days.length / totalDays) * 100
    };
  };

  switch (viewMode) {
    case 'Year': {
      return eachMonthOfInterval(range).map(monthStart => {
        const monthEnd = endOfMonth(monthStart);
        return createTimeUnit(
          monthStart,
          monthEnd,
          format(monthStart, 'MMMM', { locale: fr })
        );
      });
    }
    case 'Quarter': {
      return eachMonthOfInterval(range).map(monthStart => {
        const monthEnd = endOfMonth(monthStart);
        return createTimeUnit(
          monthStart,
          monthEnd,
          format(monthStart, 'MMMM', { locale: fr })
        );
      });
    }
    case 'Month': {
      return eachWeekOfInterval(range, { weekStartsOn: 1 }).map(weekStart => {
        const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });
        const adjustedEnd = weekEnd > range.end ? range.end : weekEnd;
        return createTimeUnit(
          weekStart,
          adjustedEnd,
          `Semaine ${format(weekStart, 'w')}`
        );
      });
    }
    case 'Week': {
      const days = eachDayOfInterval(range);
      return [{
        label: format(currentDate, "'Semaine' w", { locale: fr }),
        start: startOfDay(days[0]),
        end: startOfDay(days[days.length - 1]),
        days,
        width: 100
      }];
    }
    default:
      return [];
  }
}

export function getHeaderTitle(date: Date, viewMode: ViewMode): string {
  const formats: Record<ViewMode, string> = {
    Year: 'yyyy',
    Quarter: "'T'Q yyyy",
    Month: 'MMMM yyyy',
    Week: "'Semaine' w, MMMM yyyy'",
  };

  return format(date, formats[viewMode], { locale: fr });
}

export function calculateGridPosition(date: Date, startDate: Date, endDate: Date): number {
  const totalDays = differenceInDays(endDate, startDate) + 1;
  const dayPosition = differenceInDays(date, startDate);
  return (dayPosition / totalDays) * 100;
}
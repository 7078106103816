import { Project } from '@/types/project';
import { Card } from '@/components/ui/card';
import { motion } from 'framer-motion';
import { format, differenceInDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import { parseDate } from '@/lib/date-utils';

interface ProjectTimelineProps {
  project: Project;
}

export function ProjectTimeline({ project }: ProjectTimelineProps) {
  const endDate = parseDate(project.deadline);
  if (!endDate) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="space-y-6"
      >
        <h2 className="text-2xl font-bold text-foreground mb-4">Chronologie du projet</h2>
        <Card className="p-6">
          <div className="text-center text-muted-foreground">
            Date limite non définie
          </div>
        </Card>
      </motion.div>
    );
  }

  const startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - project.workload.estimated);
  const totalDays = differenceInDays(endDate, startDate);
  const progress = (project.workload.consumed / project.workload.estimated) * 100;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="space-y-6"
    >
      <h2 className="text-2xl font-bold text-foreground mb-4">Chronologie du projet</h2>

      <Card className="p-6">
        <div className="relative">
          <div className="absolute left-9 top-0 h-full w-px bg-border" />

          <div className="space-y-8">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="relative flex items-center"
            >
              <div className="absolute left-0 rounded-full bg-primary p-2">
                <div className="h-2 w-2 rounded-full bg-primary-foreground" />
              </div>
              <div className="ml-12">
                <div className="flex flex-col">
                  <span className="text-sm text-muted-foreground">
                    {format(startDate, 'dd MMMM yyyy', { locale: fr })}
                  </span>
                  <span className="font-medium">Début du projet</span>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="relative ml-12 h-4 w-[calc(100%-3rem)] rounded-full bg-muted"
            >
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 1, delay: 0.5 }}
                className="h-full rounded-full bg-primary"
              />
            </motion.div>

            <motion.div
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="relative flex items-center"
            >
              <div className="absolute left-0 rounded-full bg-destructive p-2">
                <div className="h-2 w-2 rounded-full bg-destructive-foreground" />
              </div>
              <div className="ml-12">
                <div className="flex flex-col">
                  <span className="text-sm text-muted-foreground">
                    {format(endDate, 'dd MMMM yyyy', { locale: fr })}
                  </span>
                  <span className="font-medium">Date limite</span>
                  <span className="text-sm text-muted-foreground">
                    Durée totale : {totalDays} jours
                  </span>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </Card>
    </motion.div>
  );
}
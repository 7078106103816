import { useState } from 'react';
import { ProjectCard } from './ProjectCard';
import { Project } from '@/types/project';
import { Loader2, AlertCircle, ArrowDown, ArrowUp, Plus } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { ProjectDetailsDialog } from './project-details/ProjectDetailsDialog';
import { ProjectDialog } from './project-form/ProjectDialog';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { filterProjects, getTechnologies } from '@/lib/filters';
import { sortProjects, SortConfig, SortOption } from '@/lib/sort';

interface DashboardProps {
  projects: Project[];
  loading: boolean;
  error: string | null;
  onRefresh?: () => Promise<void>;
}

export function Dashboard({ projects, loading, error, onRefresh }: DashboardProps) {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [selectedTechnology, setSelectedTechnology] = useState<string>('all');
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    option: 'urgency',
    direction: 'desc'
  });

  const technologies = getTechnologies(projects);
  const filteredProjects = filterProjects(projects, selectedTechnology);
  const sortedProjects = sortProjects(filteredProjects, sortConfig);

  const handleProjectClick = (project: Project) => {
    setSelectedProject(project);
    setShowDetailsDialog(true);
  };

  const handleEditClick = (project: Project) => {
    setSelectedProject(project);
    setShowEditDialog(true);
  };

  const handleCreateClick = () => {
    setSelectedProject(null);
    setShowCreateDialog(true);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen gap-8 p-4">
        <div className="flex items-center gap-2">
          <Loader2 className="w-6 h-6 animate-spin text-primary" />
          <span className="text-sm text-muted-foreground">Chargement des projets...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8 px-4">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
          <h1 className="text-3xl font-bold text-foreground">Tableau de Bord des Projets</h1>
          <div className="flex items-center gap-4">
            <Button onClick={handleCreateClick} className="gap-2">
              <Plus className="w-4 h-4" />
              Nouveau projet
            </Button>
            <div className="flex items-center gap-2">
              <Select 
                value={selectedTechnology} 
                onValueChange={setSelectedTechnology}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Filtrer par technologie" />
                </SelectTrigger>
                <SelectContent>
                  {technologies.map((tech) => (
                    <SelectItem key={tech} value={tech}>
                      {tech === 'all' ? 'Toutes les technologies' : tech}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Select 
                value={sortConfig.option} 
                onValueChange={(value) => setSortConfig(prev => ({ ...prev, option: value as SortOption }))}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Trier par" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="urgency">Niveau d'urgence</SelectItem>
                  <SelectItem value="deadline">Date limite</SelectItem>
                  <SelectItem value="progress">Progression</SelectItem>
                  <SelectItem value="workload">Charge de travail</SelectItem>
                </SelectContent>
              </Select>
              <Button
                variant="outline"
                size="icon"
                onClick={() => setSortConfig(prev => ({
                  ...prev,
                  direction: prev.direction === 'asc' ? 'desc' : 'asc'
                }))}
                className="transition-colors hover:bg-muted"
              >
                {sortConfig.direction === 'desc' ? (
                  <ArrowDown className="h-4 w-4" />
                ) : (
                  <ArrowUp className="h-4 w-4" />
                )}
              </Button>
            </div>
            <div className="text-sm text-muted-foreground">
              {sortedProjects.length} Projets
            </div>
          </div>
        </div>

        {error && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        
        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {sortedProjects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              onClick={() => handleProjectClick(project)}
              onEdit={() => handleEditClick(project)}
            />
          ))}
        </div>

        {!error && sortedProjects.length === 0 && (
          <div className="text-center py-12">
            <p className="text-muted-foreground">Aucun projet trouvé</p>
          </div>
        )}

        <ProjectDetailsDialog
          project={selectedProject}
          open={showDetailsDialog}
          onOpenChange={setShowDetailsDialog}
          onEdit={() => {
            setShowDetailsDialog(false);
            setShowEditDialog(true);
          }}
        />

        <ProjectDialog
          project={selectedProject}
          open={showEditDialog || showCreateDialog}
          onOpenChange={(open) => {
            if (!open) {
              setShowEditDialog(false);
              setShowCreateDialog(false);
            }
          }}
          onSuccess={onRefresh}
        />
      </div>
    </div>
  );
}
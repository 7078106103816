import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { chartColors, createXAxisProps, createYAxisProps, createTooltipProps } from '@/lib/chart-utils';
import { ChartContainer } from './ChartContainer';

interface WorkloadData {
  name: string;
  estimated: number;
  consumed: number;
}

interface WorkloadByTechnologyProps {
  data: WorkloadData[];
}

export function WorkloadByTechnology({ data }: WorkloadByTechnologyProps) {
  return (
    <ChartContainer>
      <BarChart data={data}>
        <XAxis {...createXAxisProps<WorkloadData>({ dataKey: "name" })} />
        <YAxis {...createYAxisProps<WorkloadData>()} />
        <Tooltip {...createTooltipProps<WorkloadData>()} />
        <Bar dataKey="estimated" fill={chartColors[0]} name="Estimée" />
        <Bar dataKey="consumed" fill={chartColors[1]} name="Consommée" />
      </BarChart>
    </ChartContainer>
  );
}
import { Project } from '@/types/project';
import { Badge } from '@/components/ui/badge';
import { Progress } from '@/components/ui/progress';
import { Card } from '@/components/ui/card';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { getTechnologyColor, getProgressColor } from '@/lib/project-utils';

interface ProjectOverviewProps {
  project: Project;
}

export function ProjectOverview({ project }: ProjectOverviewProps) {
  const progressPercentage = (project.workload.consumed / project.workload.estimated) * 100;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="space-y-6"
    >
      <div className="flex items-start justify-between">
        <div>
          <h2 className="text-2xl font-bold text-foreground">{project.name}</h2>
          <p className="text-muted-foreground">{project.description}</p>
        </div>
        <Badge
          variant={project.urgencyLevel >= 4 ? "destructive" : "secondary"}
          className={cn(
            "text-sm",
            project.urgencyLevel >= 4 && "animate-pulse"
          )}
        >
          Niveau d'urgence {project.urgencyLevel}
        </Badge>
      </div>

      <Card className="p-6">
        <div className="grid grid-cols-2 gap-6">
          <div>
            <h3 className="text-sm font-medium mb-2">Chef de projet</h3>
            <p className="text-foreground">{project.projectManager}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium mb-2">Technologie</h3>
            <Badge variant="outline" className={getTechnologyColor(project.technology)}>
              {project.technology}
            </Badge>
          </div>
          <div>
            <h3 className="text-sm font-medium mb-2">Type de travail</h3>
            <Badge variant="secondary">{project.workType}</Badge>
          </div>
          <div>
            <h3 className="text-sm font-medium mb-2">Date limite</h3>
            <p className="text-foreground">{project.deadline}</p>
          </div>
        </div>
      </Card>

      <Card className="p-6">
        <h3 className="text-sm font-medium mb-4">Progression du projet</h3>
        <div className="space-y-4">
          <div className="flex justify-between text-sm">
            <span>Charge de travail</span>
            <span className="font-medium">
              {project.workload.consumed}j / {project.workload.estimated}j
            </span>
          </div>
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            transition={{ duration: 0.5 }}
          >
            <Progress
              value={progressPercentage}
              className={getProgressColor(project.urgencyLevel)}
            />
          </motion.div>
          <div className="flex justify-between text-sm text-muted-foreground">
            <span>Temps restant</span>
            <span>{project.timeRemaining}</span>
          </div>
        </div>
      </Card>
    </motion.div>
  );
}
import { Project } from '@/types/project';
import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';

interface ProjectProgressProps {
  project: Project;
}

export function ProjectProgress({ project }: ProjectProgressProps) {
  const progressPercentage = (project.workload.consumed / project.workload.estimated) * 100;

  return (
    <div className="px-6 space-y-2">
      <div className="flex justify-between text-sm text-muted-foreground">
        <span>Charge de travail</span>
        <span className="font-medium">
          {project.workload.consumed}j / {project.workload.estimated}j
        </span>
      </div>
      
      <Progress 
        value={progressPercentage} 
        className={cn(
          "h-2.5",
          project.urgencyLevel === 5 && "bg-red-100 [&>[role=progressbar]]:bg-red-500",
          project.urgencyLevel === 4 && "bg-orange-100 [&>[role=progressbar]]:bg-orange-500",
          project.urgencyLevel === 3 && "bg-yellow-100 [&>[role=progressbar]]:bg-yellow-500",
          project.urgencyLevel === 2 && "bg-blue-100 [&>[role=progressbar]]:bg-blue-500",
          project.urgencyLevel === 1 && "bg-green-100 [&>[role=progressbar]]:bg-green-500"
        )}
      />
      
      <div className="flex justify-end">
        <span className="text-xs text-muted-foreground">
          {progressPercentage.toFixed(0)}% consommé
        </span>
      </div>
    </div>
  );
}
import { useState } from 'react';
import { Project } from '@/types/project';
import { createProject, updateProject, deleteProject } from '@/lib/api';
import { useToast } from '@/hooks/use-toast';

export function useProjectMutations() {
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const handleCreate = async (project: Omit<Project, 'id' | 'timeRemaining'>) => {
    setLoading(true);
    try {
      const newProject = await createProject(project);
      toast({
        title: "Succès",
        description: "Le projet a été créé avec succès"
      });
      return newProject;
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: "Impossible de créer le projet"
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (id: string, updates: Partial<Omit<Project, 'id' | 'timeRemaining'>>) => {
    setLoading(true);
    try {
      await updateProject(id, updates);
      toast({
        title: "Succès",
        description: "Le projet a été mis à jour avec succès"
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: "Impossible de mettre à jour le projet"
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    setLoading(true);
    try {
      await deleteProject(id);
      toast({
        title: "Succès",
        description: "Le projet a été supprimé avec succès"
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Erreur",
        description: "Impossible de supprimer le projet"
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    createProject: handleCreate,
    updateProject: handleUpdate,
    deleteProject: handleDelete
  };
}
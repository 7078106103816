import { Project } from '@/types/project';
import { Badge } from '@/components/ui/badge';
import { formatDate } from '@/lib/date-utils';

interface ProjectFooterProps {
  project: Project;
}

export function ProjectFooter({ project }: ProjectFooterProps) {
  return (
    <div className="flex items-center gap-2">
      <Badge variant="outline" className="text-muted-foreground">
        {project.timeRemaining}
      </Badge>
      <span className="text-sm">
        Date limite: {formatDate(project.deadline)}
      </span>
    </div>
  );
}
import { createBrowserRouter } from 'react-router-dom';
import { RootLayout } from '@/layouts/RootLayout';
import { authRoutes } from './auth-routes';
import { dashboardRoutes } from './dashboard-routes';

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      ...authRoutes,
      ...dashboardRoutes
    ]
  }
]);
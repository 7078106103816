import { createContext, useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { AuthState, UserProfile } from '@/types/auth';
import { loginRequest } from '@/lib/auth/msal-config';
import { validateUserDomain } from '@/lib/auth/user-validation';
import { getUserByEmail, createUser } from '@/lib/auth/user-service';

interface AuthContextType extends AuthState {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  handleAuthCallback: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { instance, accounts, inProgress } = useMsal();
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    isLoading: true,
    user: null,
    error: null
  });

  const handleAuthCallback = async () => {
    try {
      const response = await instance.handleRedirectPromise();
      
      if (response) {
        const account = response.account;
        const userEmail = account.username.toLowerCase();

        // Valider le domaine de l'email
        validateUserDomain(userEmail);

        // Récupérer ou créer l'utilisateur dans la base de données
        let user = await getUserByEmail(userEmail);
        if (!user) {
          user = await createUser({
            email: userEmail,
            display_name: account.name || '',
            first_name: account.given_name || '',
            last_name: account.family_name || '',
            session_token: response.accessToken
          });
        }

        setAuthState({
          isAuthenticated: true,
          isLoading: false,
          user,
          error: null
        });
      }
    } catch (error) {
      console.error('Auth callback error:', error);
      setAuthState({
        isAuthenticated: false,
        isLoading: false,
        user: null,
        error: error instanceof Error ? error.message : 'Une erreur est survenue'
      });
    }
  };

  const login = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error('Login error:', error);
      setAuthState(prev => ({
        ...prev,
        error: 'Échec de la connexion. Veuillez réessayer.'
      }));
    }
  };

  const logout = async () => {
    try {
      await instance.logoutRedirect();
      setAuthState({
        isAuthenticated: false,
        isLoading: false,
        user: null,
        error: null
      });
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    if (inProgress === 'none') {
      handleAuthCallback();
    }
  }, [inProgress]);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        logout,
        handleAuthCallback
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { ProjectWithDates } from './types';

interface GanttStatsProps {
  projects: ProjectWithDates[];
  technologies: string[];
}

export function GanttStats({ projects, technologies }: GanttStatsProps) {
  const activeProjects = projects.filter(p => p.isVisible);
  const inactiveProjects = projects.filter(p => !p.isVisible);

  const getProjectColor = (urgencyLevel: number) => {
    switch (urgencyLevel) {
      case 5: return 'bg-red-500';
      case 4: return 'bg-orange-500';
      case 3: return 'bg-yellow-500';
      case 2: return 'bg-blue-500';
      default: return 'bg-green-500';
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4 text-foreground">Légende des priorités</h3>
        <div className="space-y-2">
          {[5, 4, 3, 2, 1].map((level) => (
            <div key={level} className="flex items-center gap-2">
              <div className={cn("w-3 h-3 rounded-full", getProjectColor(level))} />
              <span className="text-foreground">Niveau {level}</span>
            </div>
          ))}
          <div className="flex items-center gap-2 pt-2 border-t">
            <div className="w-3 h-3 rounded-full bg-gray-300" />
            <span className="text-muted-foreground">Hors période</span>
          </div>
        </div>
      </Card>

      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4 text-foreground">Statistiques de la période</h3>
        <div className="space-y-4">
          <div>
            <div className="text-sm text-muted-foreground">Projets actifs</div>
            <div className="text-2xl font-bold text-foreground">
              {activeProjects.length}
            </div>
          </div>
          <div>
            <div className="text-sm text-muted-foreground">Projets hors période</div>
            <div className="text-2xl font-bold text-foreground">
              {inactiveProjects.length}
            </div>
          </div>
          <div>
            <div className="text-sm text-muted-foreground">Projets urgents</div>
            <div className="text-2xl font-bold text-foreground">
              {activeProjects.filter(p => p.urgencyLevel >= 4).length}
            </div>
          </div>
        </div>
      </Card>

      <Card className="p-6">
        <h3 className="text-lg font-semibold mb-4 text-foreground">Répartition par technologie</h3>
        <div className="space-y-2">
          {technologies
            .filter(tech => tech !== 'all')
            .map(tech => {
              const activeCount = activeProjects.filter(p => p.technology === tech).length;
              const inactiveCount = inactiveProjects.filter(p => p.technology === tech).length;
              return (
                <div key={tech} className="flex items-center justify-between">
                  <span className="text-foreground">{tech}</span>
                  <div className="flex items-center gap-2">
                    <Badge variant="secondary" className="bg-primary/10">
                      {activeCount} actif{activeCount > 1 ? 's' : ''}
                    </Badge>
                    {inactiveCount > 0 && (
                      <Badge variant="outline" className="text-muted-foreground">
                        {inactiveCount} inactif{inactiveCount > 1 ? 's' : ''}
                      </Badge>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </Card>
    </div>
  );
}
import { Technology } from '@/types/project';

export function getTechnologyColor(tech: Technology): string {
  const colors = {
    Android: 'bg-green-100 text-green-800',
    Flutter: 'bg-blue-100 text-blue-800',
    iOS: 'bg-gray-100 text-gray-800'
  };
  return colors[tech] || 'bg-gray-100 text-gray-800';
}

export function getUrgencyColor(level: number): string {
  switch (level) {
    case 5: return 'bg-red-500';
    case 4: return 'bg-orange-500';
    case 3: return 'bg-yellow-500';
    case 2: return 'bg-blue-500';
    default: return 'bg-green-500';
  }
}

export function getProgressColor(level: number): string {
  switch (level) {
    case 5: return 'bg-red-100 [&>[role=progressbar]]:bg-red-500';
    case 4: return 'bg-orange-100 [&>[role=progressbar]]:bg-orange-500';
    case 3: return 'bg-yellow-100 [&>[role=progressbar]]:bg-yellow-500';
    case 2: return 'bg-blue-100 [&>[role=progressbar]]:bg-blue-500';
    default: return 'bg-green-100 [&>[role=progressbar]]:bg-green-500';
  }
}
import { Project } from '@/types/project';

export function filterProjects(projects: Project[], technology: string): Project[] {
  if (!technology || technology === 'all') {
    return projects;
  }
  
  return projects.filter(project => project.technology === technology);
}

export function getTechnologies(projects: Project[]): string[] {
  const uniqueTechnologies = new Set(projects.map(project => project.technology));
  return ['all', ...Array.from(uniqueTechnologies)];
}
import { Navigate } from 'react-router-dom';
import { AuthLayout } from '@/layouts/AuthLayout';
import { LoginPage } from '@/pages/auth/LoginPage';
import { AuthCallback } from '@/pages/auth/AuthCallback';
import { ROUTES } from '@/lib/constants';

export const authRoutes = [
  {
    path: '/',
    element: <Navigate to={ROUTES.AUTH.LOGIN} replace />
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: 'callback',
        element: <AuthCallback />
      }
    ]
  }
];
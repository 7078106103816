import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { ProjectForm } from './form/ProjectForm';
import { Project } from '@/types/project';
import { useProjectMutations } from '@/hooks/use-project-mutations';

interface ProjectDialogProps {
  project?: Project | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess?: () => void;
}

export function ProjectDialog({ project, open, onOpenChange, onSuccess }: ProjectDialogProps) {
  const { createProject, updateProject, loading } = useProjectMutations();

  const handleSubmit = async (data: Omit<Project, 'id' | 'timeRemaining'>) => {
    try {
      if (project) {
        await updateProject(project.id, data);
      } else {
        await createProject(data);
      }
      onSuccess?.();
      onOpenChange(false);
    } catch (error) {
      console.error('Error saving project:', error);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>
            {project ? 'Modifier le projet' : 'Nouveau projet'}
          </DialogTitle>
        </DialogHeader>
        <ProjectForm
          project={project}
          onSubmit={handleSubmit}
          onCancel={() => onOpenChange(false)}
          isLoading={loading}
        />
      </DialogContent>
    </Dialog>
  );
}
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { chartColors, createXAxisProps, createYAxisProps, createTooltipProps } from '@/lib/chart-utils';
import { ChartContainer } from './ChartContainer';

interface TeamData {
  name: string;
  members: number;
  workload: number;
}

interface TeamSizeChartProps {
  data: TeamData[];
}

export function TeamSizeChart({ data }: TeamSizeChartProps) {
  return (
    <ChartContainer>
      <LineChart data={data}>
        <XAxis {...createXAxisProps<TeamData>({ dataKey: "name" })} />
        <YAxis 
          {...createYAxisProps<TeamData>({ 
            yAxisId: "left",
            orientation: "left",
            stroke: chartColors[0]
          })} 
        />
        <YAxis 
          {...createYAxisProps<TeamData>({ 
            yAxisId: "right",
            orientation: "right",
            stroke: chartColors[1]
          })} 
        />
        <Tooltip {...createTooltipProps<TeamData>()} />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="members"
          stroke={chartColors[0]}
          name="Membres"
          strokeWidth={2}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="workload"
          stroke={chartColors[1]}
          name="Charge (j)"
          strokeWidth={2}
        />
      </LineChart>
    </ChartContainer>
  );
}
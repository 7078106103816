import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { LayoutDashboard, BarChart2, GanttChart, Settings, Bell, UserCircle2, LogOut } from "lucide-react";
import { Logo } from "@/components/ui/logo";

interface SidebarProps {
  className?: string;
  currentView: 'dashboard' | 'stats' | 'gantt';
  onViewChange: (view: 'dashboard' | 'stats' | 'gantt') => void;
}

export function Sidebar({ className, currentView, onViewChange }: SidebarProps) {
  return (
    <div className={cn("flex flex-col h-screen border-r bg-zinc-900", className)}>
      <div className="p-6 flex justify-center">
        <img 
          src="https://i.postimg.cc/GtzLG791/Mediamodifier-Design-Template.png" 
          alt="Simplify" 
          className="h-16 w-auto"
        />
      </div>
      <ScrollArea className="flex-1 px-4">
        <div className="space-y-2">
          <Button
            variant={currentView === 'dashboard' ? 'secondary' : 'ghost'}
            className={cn(
              "w-full justify-start gap-2",
              currentView === 'dashboard' ? 'text-white bg-blue-600 hover:bg-blue-700' : 'text-zinc-300 hover:text-white hover:bg-zinc-800'
            )}
            onClick={() => onViewChange('dashboard')}
          >
            <LayoutDashboard className="h-5 w-5" />
            Tableau de bord
          </Button>
          <Button
            variant={currentView === 'stats' ? 'secondary' : 'ghost'}
            className={cn(
              "w-full justify-start gap-2",
              currentView === 'stats' ? 'text-white bg-blue-600 hover:bg-blue-700' : 'text-zinc-300 hover:text-white hover:bg-zinc-800'
            )}
            onClick={() => onViewChange('stats')}
          >
            <BarChart2 className="h-5 w-5" />
            Statistiques
          </Button>
          <Button
            variant={currentView === 'gantt' ? 'secondary' : 'ghost'}
            className={cn(
              "w-full justify-start gap-2",
              currentView === 'gantt' ? 'text-white bg-blue-600 hover:bg-blue-700' : 'text-zinc-300 hover:text-white hover:bg-zinc-800'
            )}
            onClick={() => onViewChange('gantt')}
          >
            <GanttChart className="h-5 w-5" />
            Planning
          </Button>
        </div>
        <div className="mt-4 pt-4 border-t border-zinc-800">
          <div className="space-y-2">
            <Button variant="ghost" className="w-full justify-start gap-2 text-zinc-300 hover:text-white hover:bg-zinc-800">
              <Bell className="h-5 w-5" />
              Notifications
            </Button>
            <Button variant="ghost" className="w-full justify-start gap-2 text-zinc-300 hover:text-white hover:bg-zinc-800">
              <UserCircle2 className="h-5 w-5" />
              Profil
            </Button>
            <Button variant="ghost" className="w-full justify-start gap-2 text-zinc-300 hover:text-white hover:bg-zinc-800">
              <Settings className="h-5 w-5" />
              Paramètres
            </Button>
          </div>
        </div>
      </ScrollArea>
      <div className="p-4 mt-auto border-t border-zinc-800">
        <Button 
          variant="ghost" 
          className="w-full justify-start gap-2 text-red-400 hover:text-red-300 hover:bg-zinc-800"
        >
          <LogOut className="h-5 w-5" />
          Déconnexion
        </Button>
      </div>
    </div>
  );
}
import { Project } from '@/types/project';
import { ProjectCard } from '@/components/project/ProjectCard';

interface DashboardGridProps {
  projects: Project[];
  onProjectClick: (project: Project) => void;
  onProjectEdit: (project: Project) => void;
}

export function DashboardGrid({ projects, onProjectClick, onProjectEdit }: DashboardGridProps) {
  return (
    <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {projects.map((project) => (
        <ProjectCard
          key={project.id}
          project={project}
          onClick={() => onProjectClick(project)}
          onEdit={() => onProjectEdit(project)}
        />
      ))}
      {projects.length === 0 && (
        <div className="col-span-full text-center py-12">
          <p className="text-muted-foreground">Aucun projet trouvé</p>
        </div>
      )}
    </div>
  );
}
import { Users } from 'lucide-react';
import { TeamMember } from '@/types/project';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

interface ProjectTeamProps {
  team: TeamMember[];
}

export function ProjectTeam({ team }: ProjectTeamProps) {
  const teamLead = team.find(member => member.isLead);
  const teamMembers = team.filter(member => !member.isLead);

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <Users className="h-4 w-4 text-muted-foreground" />
          <span className="text-xs text-muted-foreground">Équipe:</span>
        </div>
        
        <div className="flex flex-col gap-2">
          {teamLead && (
            <div className="flex items-center gap-2">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Avatar className="h-8 w-8 border-2 border-primary ring-2 ring-background">
                      <AvatarImage src={teamLead.avatar} alt={teamLead.name} />
                      <AvatarFallback>{teamLead.name[0]}</AvatarFallback>
                    </Avatar>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="font-semibold">{teamLead.name}</p>
                    <p className="text-xs text-muted-foreground">
                      {teamLead.isTechLead ? 'Tech Lead' : teamLead.role}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <span className="text-sm font-medium">{teamLead.name}</span>
            </div>
          )}
          
          <div className="flex -space-x-2">
            <TooltipProvider>
              {teamMembers.map((member) => (
                <Tooltip key={member.id}>
                  <TooltipTrigger>
                    <Avatar className="h-6 w-6 border-2 border-background">
                      <AvatarImage src={member.avatar} alt={member.name} />
                      <AvatarFallback>{member.name[0]}</AvatarFallback>
                    </Avatar>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{member.name}</p>
                    <p className="text-xs text-muted-foreground">
                      {member.isTechLead ? 'Tech Lead' : member.role}
                    </p>
                  </TooltipContent>
                </Tooltip>
              ))}
            </TooltipProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
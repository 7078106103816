import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: '960c807c-0901-4856-97c4-58ebb09c3c53',
    authority: 'https://login.microsoftonline.com/47f17681-508d-48fd-ad20-8c8a821f05e7',
    redirectUri: window.location.origin + '/auth/callback',
    postLogoutRedirectUri: window.location.origin + '/logout',
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'email', 'User.Read']
};
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { chartColors, createXAxisProps, createYAxisProps, createTooltipProps } from '@/lib/chart-utils';
import { ChartContainer } from './ChartContainer';

interface UrgencyData {
  name: string;
  count: number;
}

interface UrgencyDistributionProps {
  data: UrgencyData[];
}

export function UrgencyDistribution({ data }: UrgencyDistributionProps) {
  return (
    <ChartContainer>
      <BarChart data={data}>
        <XAxis {...createXAxisProps<UrgencyData>({ dataKey: "name" })} />
        <YAxis {...createYAxisProps<UrgencyData>()} />
        <Tooltip {...createTooltipProps<UrgencyData>()} />
        <Bar dataKey="count" fill={chartColors[2]} name="Projets" />
      </BarChart>
    </ChartContainer>
  );
}
import { XAxisProps, YAxisProps, TooltipProps } from 'recharts';

interface DefaultAxisProps {
  stroke: string;
  fontSize: number;
  tickLine: boolean;
  axisLine: { strokeWidth: number };
  tick: { fill: string };
}

const defaultAxisProps: DefaultAxisProps = {
  stroke: 'hsl(var(--foreground))',
  fontSize: 12,
  tickLine: false,
  axisLine: { strokeWidth: 1 },
  tick: { fill: 'hsl(var(--foreground))' }
};

interface DefaultTooltipProps {
  contentStyle: {
    backgroundColor: string;
    border: string;
    borderRadius: string;
    padding: string;
  };
  itemStyle: { color: string };
  labelStyle: { color: string };
}

const defaultTooltipProps: DefaultTooltipProps = {
  contentStyle: {
    backgroundColor: 'hsl(var(--background))',
    border: '1px solid hsl(var(--border))',
    borderRadius: '8px',
    padding: '8px 12px'
  },
  itemStyle: { color: 'hsl(var(--foreground))' },
  labelStyle: { color: 'hsl(var(--foreground))' }
};

export const chartColors = [
  'hsl(var(--chart-1))',
  'hsl(var(--chart-2))',
  'hsl(var(--chart-3))'
] as const;

export function createXAxisProps<T>(props?: Partial<XAxisProps<T>>): XAxisProps<T> {
  return {
    ...defaultAxisProps,
    ...props
  };
}

export function createYAxisProps<T>(props?: Partial<YAxisProps<T>>): YAxisProps<T> {
  return {
    ...defaultAxisProps,
    ...props
  };
}

export function createTooltipProps<T>(props?: Partial<TooltipProps<any, any>>): TooltipProps<any, any> {
  return {
    ...defaultTooltipProps,
    ...props
  };
}
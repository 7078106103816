import { supabase } from './supabase';
import { Project } from '@/types/project';
import { calculateTimeRemaining } from '@/lib/date-utils';

export async function fetchProjects(): Promise<Project[]> {
  try {
    const { data: projects, error: projectsError } = await supabase
      .from('projects')
      .select(`
        id,
        name,
        logo,
        urgency_level,
        progress,
        deadline,
        work_type,
        description,
        technology,
        workload_estimated,
        workload_consumed,
        project_manager,
        team_members (
          id,
          name,
          avatar,
          role,
          is_lead,
          is_tech_lead
        )
      `)
      .order('created_at', { ascending: false });

    if (projectsError) {
      console.error('Error fetching projects:', projectsError);
      throw projectsError;
    }

    if (!projects) {
      return [];
    }

    return projects.map(project => ({
      id: project.id,
      name: project.name,
      logo: project.logo,
      urgencyLevel: project.urgency_level,
      progress: project.progress,
      deadline: project.deadline,
      workType: project.work_type,
      description: project.description,
      technology: project.technology,
      workload: {
        estimated: project.workload_estimated,
        consumed: project.workload_consumed
      },
      timeRemaining: calculateTimeRemaining(project.deadline),
      team: project.team_members.map(member => ({
        id: member.id,
        name: member.name,
        avatar: member.avatar,
        role: member.role,
        isLead: member.is_lead,
        isTechLead: member.is_tech_lead
      })),
      projectManager: project.project_manager
    }));
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
}

export async function createProject(project: Omit<Project, 'id' | 'timeRemaining'>): Promise<Project> {
  const { data, error } = await supabase
    .from('projects')
    .insert([{
      name: project.name,
      logo: project.logo,
      urgency_level: project.urgencyLevel,
      progress: project.progress,
      deadline: project.deadline,
      work_type: project.workType,
      description: project.description,
      technology: project.technology,
      workload_estimated: project.workload.estimated,
      workload_consumed: project.workload.consumed,
      project_manager: project.projectManager
    }])
    .select()
    .single();

  if (error) throw error;
  if (!data) throw new Error('No data returned from insert');

  // Insert team members
  if (project.team.length > 0) {
    const { error: teamError } = await supabase
      .from('team_members')
      .insert(
        project.team.map(member => ({
          project_id: data.id,
          name: member.name,
          avatar: member.avatar,
          role: member.role,
          is_lead: member.isLead,
          is_tech_lead: member.isTechLead
        }))
      );

    if (teamError) throw teamError;
  }

  return {
    ...project,
    id: data.id,
    timeRemaining: calculateTimeRemaining(project.deadline)
  };
}

export async function updateProject(
  id: string,
  updates: Partial<Omit<Project, 'id' | 'timeRemaining'>>
): Promise<void> {
  const { error } = await supabase
    .from('projects')
    .update({
      name: updates.name,
      logo: updates.logo,
      urgency_level: updates.urgencyLevel,
      progress: updates.progress,
      deadline: updates.deadline,
      work_type: updates.workType,
      description: updates.description,
      technology: updates.technology,
      workload_estimated: updates.workload?.estimated,
      workload_consumed: updates.workload?.consumed,
      project_manager: updates.projectManager
    })
    .eq('id', id);

  if (error) throw error;

  if (updates.team) {
    // Delete existing team members
    await supabase
      .from('team_members')
      .delete()
      .eq('project_id', id);

    // Insert new team members
    if (updates.team.length > 0) {
      const { error: teamError } = await supabase
        .from('team_members')
        .insert(
          updates.team.map(member => ({
            project_id: id,
            name: member.name,
            avatar: member.avatar,
            role: member.role,
            is_lead: member.isLead,
            is_tech_lead: member.isTechLead
          }))
        );

      if (teamError) throw teamError;
    }
  }
}

export async function deleteProject(id: string): Promise<void> {
  const { error } = await supabase
    .from('projects')
    .delete()
    .eq('id', id);

  if (error) throw error;
}
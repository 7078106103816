import { Users } from 'lucide-react';
import { Project } from '@/types/project';
import { ProjectHeader } from './card/ProjectHeader';
import { ProjectProgress } from './card/ProjectProgress';
import { ProjectTeam } from './card/ProjectTeam';
import { ProjectFooter } from './card/ProjectFooter';
import { Card } from '@/components/ui/card';
import { motion } from 'framer-motion';

interface ProjectCardProps {
  project: Project;
  onClick: () => void;
  onEdit: () => void;
}

export function ProjectCard({ project, onClick, onEdit }: ProjectCardProps) {
  const isHighPriority = project.urgencyLevel >= 4;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      whileHover={{ y: -4 }}
    >
      <Card 
        className={cn(
          "w-full transition-all relative group",
          isHighPriority && "ring-2 ring-red-100"
        )}
      >
        <div onClick={onClick} className="cursor-pointer">
          <ProjectHeader 
            project={project} 
            onEdit={onEdit} 
          />
          
          <ProjectProgress project={project} />
          
          <div className="p-6 space-y-4">
            <p className="text-sm text-muted-foreground line-clamp-2">
              {project.description}
            </p>

            <ProjectTeam team={project.team} />
            
            <ProjectFooter project={project} />
          </div>
        </div>
      </Card>
    </motion.div>
  );
}
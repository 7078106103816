import { Project } from '@/types/project';
import { getInitials } from '@/lib/string-utils';

interface ProjectLogoProps {
  project: Project;
}

export function ProjectLogo({ project }: ProjectLogoProps) {
  const initials = getInitials(project.name);

  return (
    <div className="h-16 w-16 rounded-lg overflow-hidden bg-gradient-to-br from-primary/10 to-primary/20 flex items-center justify-center">
      {project.logo ? (
        <img
          src={project.logo}
          alt={project.name}
          className="h-full w-full object-cover"
          onError={(e) => {
            e.currentTarget.style.display = 'none';
            e.currentTarget.parentElement!.innerHTML = `
              <div class="h-full w-full flex items-center justify-center text-lg font-semibold text-primary/70">
                ${initials}
              </div>
            `;
          }}
        />
      ) : (
        <div className="h-full w-full flex items-center justify-center text-lg font-semibold text-primary/70">
          {initials}
        </div>
      )}
    </div>
  );
}
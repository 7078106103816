import { Users, Edit } from 'lucide-react';
import { Project } from '@/types/project';
import { cn } from '@/lib/utils';
import { Progress } from '@/components/ui/progress';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { PriorityIndicator } from './PriorityIndicator';
import { motion } from 'framer-motion';

interface ProjectCardProps {
  project: Project;
  onClick: () => void;
  onEdit: () => void;
}

const getTechnologyColor = (tech: string) => {
  const colors = {
    Android: 'bg-green-100 text-green-800',
    Flutter: 'bg-blue-100 text-blue-800',
    iOS: 'bg-gray-100 text-gray-800'
  };
  return colors[tech as keyof typeof colors] || 'bg-gray-100 text-gray-800';
};

const formatDate = (dateStr: string) => {
  if (!dateStr || dateStr === '----') return 'Non défini';
  
  const [day, month, year] = dateStr.split('/');
  if (!day || !month || !year) return 'Date invalide';
  
  return `${day}/${month}/${year}`;
};

const getInitials = (projectName: string) => {
  return projectName
    .split(/\s+/)
    .map(word => word[0])
    .join('')
    .slice(0, 2)
    .toUpperCase();
};

export function ProjectCard({ project, onClick, onEdit }: ProjectCardProps) {
  const techColor = getTechnologyColor(project.technology);
  const formattedDeadline = formatDate(project.deadline);
  const isHighPriority = project.urgencyLevel >= 4;
  const progressPercentage = (project.workload.consumed / project.workload.estimated) * 100;
  const teamLead = project.team.find(member => member.isLead);
  const teamMembers = project.team.filter(member => !member.isLead);
  const initials = getInitials(project.name);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      whileHover={{ y: -4 }}
    >
      <Card 
        className={cn(
          "w-full transition-all relative group",
          isHighPriority && "ring-2 ring-red-100"
        )}
      >
        <Button
          variant="ghost"
          size="icon"
          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity z-10"
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
        >
          <Edit className="w-4 h-4" />
        </Button>

        <div onClick={onClick} className="cursor-pointer">
          <PriorityIndicator level={project.urgencyLevel} />
          
          <CardHeader className="flex flex-row items-center gap-4 space-y-0 pt-8">
            <div className="h-16 w-16 rounded-lg overflow-hidden bg-gradient-to-br from-primary/10 to-primary/20 flex items-center justify-center">
              {project.logo ? (
                <img
                  src={project.logo}
                  alt={project.name}
                  className="h-full w-full object-cover"
                  onError={(e) => {
                    e.currentTarget.style.display = 'none';
                    e.currentTarget.parentElement!.innerHTML = `<div class="h-full w-full flex items-center justify-center text-lg font-semibold text-primary/70">${initials}</div>`;
                  }}
                />
              ) : (
                <div className="h-full w-full flex items-center justify-center text-lg font-semibold text-primary/70">
                  {initials}
                </div>
              )}
            </div>
            <div className="flex-1">
              <div className="flex flex-col gap-1">
                <h3 className="font-semibold text-lg">{project.name}</h3>
                <div className="flex items-center gap-2">
                  <span className="text-sm text-muted-foreground">Chef de projet:</span>
                  <span className="text-sm font-medium">{project.projectManager}</span>
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-2">
                <div className="flex items-center gap-2">
                  <Badge variant="secondary" className={techColor}>
                    {project.technology}
                  </Badge>
                  {isHighPriority && (
                    <Badge variant="destructive" className="animate-[pulse_2s_ease-in-out_infinite]">
                      Urgent
                    </Badge>
                  )}
                </div>
                <div className="flex items-center">
                  <Badge variant="outline" className="text-muted-foreground">
                    {project.workType}
                  </Badge>
                </div>
              </div>
            </div>
          </CardHeader>

          <CardContent>
            <div className="space-y-4">
              <p className="text-sm text-muted-foreground line-clamp-2">
                {project.description}
              </p>

              <div>
                <div className="flex justify-between text-sm text-muted-foreground mb-2">
                  <span>Charge de travail</span>
                  <span className="font-medium">
                    {project.workload.consumed}j / {project.workload.estimated}j
                  </span>
                </div>
                <Progress 
                  value={progressPercentage} 
                  className={cn(
                    "h-2.5",
                    project.urgencyLevel === 5 && "bg-red-100 [&>[role=progressbar]]:bg-red-500",
                    project.urgencyLevel === 4 && "bg-orange-100 [&>[role=progressbar]]:bg-orange-500",
                    project.urgencyLevel === 3 && "bg-yellow-100 [&>[role=progressbar]]:bg-yellow-500",
                    project.urgencyLevel === 2 && "bg-blue-100 [&>[role=progressbar]]:bg-blue-500",
                    project.urgencyLevel === 1 && "bg-green-100 [&>[role=progressbar]]:bg-green-500"
                  )}
                />
                <div className="flex justify-end mt-1">
                  <span className="text-xs text-muted-foreground">
                    {progressPercentage.toFixed(0)}% consommé
                  </span>
                </div>
              </div>

              <div className="flex flex-col space-y-4">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <Users className="h-4 w-4 text-muted-foreground" />
                    <span className="text-xs text-muted-foreground">Équipe:</span>
                  </div>
                  <div className="flex flex-col gap-2">
                    {teamLead && (
                      <div className="flex items-center gap-2">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <Avatar className="h-8 w-8 border-2 border-primary ring-2 ring-background">
                                <AvatarImage src={teamLead.avatar} alt={teamLead.name} />
                                <AvatarFallback>{teamLead.name[0]}</AvatarFallback>
                              </Avatar>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className="font-semibold">{teamLead.name}</p>
                              <p className="text-xs text-muted-foreground">
                                {teamLead.isTechLead ? 'Tech Lead' : teamLead.role}
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                        <span className="text-sm font-medium">{teamLead.name}</span>
                      </div>
                    )}
                    <div className="flex -space-x-2">
                      <TooltipProvider>
                        {teamMembers.map((member) => (
                          <Tooltip key={member.id}>
                            <TooltipTrigger>
                              <Avatar className="h-6 w-6 border-2 border-background">
                                <AvatarImage src={member.avatar} alt={member.name} />
                                <AvatarFallback>{member.name[0]}</AvatarFallback>
                              </Avatar>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>{member.name}</p>
                              <p className="text-xs text-muted-foreground">
                                {member.isTechLead ? 'Tech Lead' : member.role}
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        ))}
                      </TooltipProvider>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-2 mt-2">
                  <Badge variant="outline" className="text-muted-foreground">
                    {project.timeRemaining}
                  </Badge>
                  <span className="text-sm">Date limite: {formattedDeadline}</span>
                </div>
              </div>
            </div>
          </CardContent>
        </div>
      </Card>
    </motion.div>
  );
}
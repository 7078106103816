import { Project } from '@/types/project';
import { Card } from '@/components/ui/card';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { motion } from 'framer-motion';
import { chartColors, createXAxisProps, createYAxisProps, createTooltipProps } from '../charts/ChartStyles';

interface ProjectMetricsProps {
  project: Project;
}

export function ProjectMetrics({ project }: ProjectMetricsProps) {
  const workloadData = [
    { name: 'Estimé', value: project.workload.estimated },
    { name: 'Consommé', value: project.workload.consumed },
    { name: 'Restant', value: project.workload.estimated - project.workload.consumed }
  ];

  const teamRoles = project.team.reduce((acc, member) => {
    acc[member.role] = (acc[member.role] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const roleData = Object.entries(teamRoles).map(([role, count]) => ({
    name: role,
    value: count
  }));

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="space-y-6"
    >
      <h2 className="text-2xl font-bold text-foreground mb-4">Métriques du projet</h2>
      
      <div className="grid md:grid-cols-2 gap-6">
        <Card className="p-6">
          <h3 className="text-lg font-medium mb-4">Distribution de la charge</h3>
          <div className="h-[300px]">
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={workloadData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {workloadData.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
                  ))}
                </Pie>
                <Tooltip {...createTooltipProps()} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Card>

        <Card className="p-6">
          <h3 className="text-lg font-medium mb-4">Répartition des rôles</h3>
          <div className="h-[300px]">
            <ResponsiveContainer>
              <BarChart data={roleData}>
                <XAxis {...createXAxisProps({ dataKey: "name" })} />
                <YAxis {...createYAxisProps()} />
                <Tooltip {...createTooltipProps()} />
                <Bar dataKey="value" fill={chartColors[0]} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </div>
    </motion.div>
  );
}
import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Project } from '@/types/project';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ProjectOverview } from './ProjectOverview';
import { ProjectTeam } from './ProjectTeam';
import { ProjectMetrics } from './ProjectMetrics';
import { ProjectTimeline } from './ProjectTimeline';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';

interface ProjectDetailsDialogProps {
  project: Project | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function ProjectDetailsDialog({ project, open, onOpenChange }: ProjectDetailsDialogProps) {
  const [activeTab, setActiveTab] = useState('overview');

  if (!project) return null;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto p-0 gap-0">
        <DialogHeader className="px-6 pt-6 pb-2">
          <DialogTitle className="text-2xl font-bold flex items-center gap-2">
            {project.name}
          </DialogTitle>
        </DialogHeader>

        <Tabs
          value={activeTab}
          onValueChange={setActiveTab}
          className="w-full"
        >
          <div className="sticky top-0 z-50 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border-b">
            <div className="flex items-center justify-between px-6 pb-2">
              <TabsList className="h-9">
                <TabsTrigger
                  value="overview"
                  className={cn(
                    "data-[state=active]:bg-primary data-[state=active]:text-primary-foreground",
                    "transition-all duration-200"
                  )}
                >
                  Vue d'ensemble
                </TabsTrigger>
                <TabsTrigger
                  value="team"
                  className={cn(
                    "data-[state=active]:bg-primary data-[state=active]:text-primary-foreground",
                    "transition-all duration-200"
                  )}
                >
                  Équipe
                </TabsTrigger>
                <TabsTrigger
                  value="metrics"
                  className={cn(
                    "data-[state=active]:bg-primary data-[state=active]:text-primary-foreground",
                    "transition-all duration-200"
                  )}
                >
                  Métriques
                </TabsTrigger>
                <TabsTrigger
                  value="timeline"
                  className={cn(
                    "data-[state=active]:bg-primary data-[state=active]:text-primary-foreground",
                    "transition-all duration-200"
                  )}
                >
                  Chronologie
                </TabsTrigger>
              </TabsList>
            </div>
          </div>

          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="p-6 pt-2"
            >
              <TabsContent value="overview" forceMount>
                <ProjectOverview project={project} />
              </TabsContent>
              <TabsContent value="team" forceMount>
                <ProjectTeam project={project} />
              </TabsContent>
              <TabsContent value="metrics" forceMount>
                <ProjectMetrics project={project} />
              </TabsContent>
              <TabsContent value="timeline" forceMount>
                <ProjectTimeline project={project} />
              </TabsContent>
            </motion.div>
          </AnimatePresence>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}
import { z } from 'zod';
import { parseDate } from '@/lib/date-utils';

export const projectSchema = z.object({
  name: z.string().min(1, 'Le nom est requis'),
  logo: z.string().url('URL invalide').optional(),
  urgencyLevel: z.number().min(1).max(5),
  progress: z.number().min(0).max(100),
  deadline: z.string().regex(/^\d{2}\/\d{2}\/\d{4}$/, 'Format de date invalide (JJ/MM/AAAA)'),
  workType: z.string().min(1, 'Le type de travail est requis'),
  description: z.string().min(1, 'La description est requise'),
  technology: z.enum(['Android', 'Flutter', 'iOS']),
  workload: z.object({
    estimated: z.number().min(0),
    consumed: z.number().min(0)
  }),
  projectManager: z.string().min(1, 'Le chef de projet est requis'),
  team: z.array(z.object({
    name: z.string().min(1),
    role: z.string().min(1),
    isLead: z.boolean().optional(),
    isTechLead: z.boolean().optional(),
    avatar: z.string().url('URL invalide').optional()
  }))
});

export type ProjectFormData = z.infer<typeof projectSchema>;

export function validateProject(data: unknown) {
  return projectSchema.parse(data);
}
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { ArrowDown, ArrowUp } from 'lucide-react';
import { SortConfig, SortOption } from '@/lib/sort';

interface DashboardFiltersProps {
  selectedTechnology: string;
  technologies: string[];
  sortConfig: SortConfig;
  projectCount: number;
  onTechnologyChange: (value: string) => void;
  onSortChange: (option: SortOption) => void;
  onSortDirectionChange: () => void;
}

export function DashboardFilters({
  selectedTechnology,
  technologies,
  sortConfig,
  projectCount,
  onTechnologyChange,
  onSortChange,
  onSortDirectionChange
}: DashboardFiltersProps) {
  return (
    <div className="flex items-center gap-4">
      <Select value={selectedTechnology} onValueChange={onTechnologyChange}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Filtrer par technologie" />
        </SelectTrigger>
        <SelectContent>
          {technologies.map((tech) => (
            <SelectItem key={tech} value={tech}>
              {tech === 'all' ? 'Toutes les technologies' : tech}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Select 
        value={sortConfig.option} 
        onValueChange={(value) => onSortChange(value as SortOption)}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Trier par" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="urgency">Niveau d'urgence</SelectItem>
          <SelectItem value="deadline">Date limite</SelectItem>
          <SelectItem value="progress">Progression</SelectItem>
          <SelectItem value="workload">Charge de travail</SelectItem>
        </SelectContent>
      </Select>

      <Button
        variant="outline"
        size="icon"
        onClick={onSortDirectionChange}
        className="transition-colors hover:bg-muted"
      >
        {sortConfig.direction === 'desc' ? (
          <ArrowDown className="h-4 w-4" />
        ) : (
          <ArrowUp className="h-4 w-4" />
        )}
      </Button>

      <div className="text-sm text-muted-foreground">
        {projectCount} Projets
      </div>
    </div>
  );
}
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

interface DashboardHeaderProps {
  onCreateProject: () => void;
}

export function DashboardHeader({ onCreateProject }: DashboardHeaderProps) {
  return (
    <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
      <h1 className="text-3xl font-bold text-foreground">Tableau de Bord des Projets</h1>
      <Button onClick={onCreateProject} className="gap-2">
        <Plus className="w-4 h-4" />
        Nouveau projet
      </Button>
    </div>
  );
}
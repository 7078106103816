import { Control } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

interface ProjectWorkloadProps {
  control: Control<any>;
}

export function ProjectWorkload({ control }: ProjectWorkloadProps) {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Charge de travail</h3>
      <div className="grid grid-cols-2 gap-4">
        <FormField
          control={control}
          name="workload.estimated"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Charge estimée (jours)</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  onChange={(e) => field.onChange(parseFloat(e.target.value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="workload.consumed"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Charge consommée (jours)</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  onChange={(e) => field.onChange(parseFloat(e.target.value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <FormField
        control={control}
        name="projectManager"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Chef de projet</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Calendar, Filter } from 'lucide-react';
import { format, getQuarter } from 'date-fns';
import { fr } from 'date-fns/locale';
import { cn } from '@/lib/utils';
import { ViewMode } from './types';

interface GanttHeaderProps {
  viewMode: ViewMode;
  currentDate: Date;
  technologies: string[];
  filteredTechnology: string;
  onViewModeChange: (mode: ViewMode) => void;
  onFilterChange: (tech: string) => void;
  onNavigate: (direction: 'prev' | 'next') => void;
}

const formatDate = (date: Date, mode: ViewMode): string => {
  switch (mode) {
    case 'Year':
      return format(date, 'yyyy');
    case 'Quarter':
      return `T${getQuarter(date)} ${format(date, 'yyyy')}`;
    case 'Month':
      return format(date, 'MMMM yyyy', { locale: fr });
    case 'Week':
      return `Semaine ${format(date, 'w')} - ${format(date, 'MMMM yyyy', { locale: fr })}`;
  }
};

const viewModeLabels: Record<ViewMode, string> = {
  Year: 'Année',
  Quarter: 'Trimestre',
  Month: 'Mois',
  Week: 'Semaine'
};

export function GanttHeader({
  viewMode,
  currentDate,
  technologies,
  filteredTechnology,
  onViewModeChange,
  onFilterChange,
  onNavigate
}: GanttHeaderProps) {
  return (
    <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
      <div className="flex items-center gap-4">
        <h1 className="text-3xl font-bold text-foreground">Planning des Projets</h1>
        <img 
          src="/adria-logo.png" 
          alt="Adria Business & Technology" 
          className="h-8 object-contain"
          onError={(e) => {
            e.currentTarget.style.display = 'none';
            const fallback = document.createElement('div');
            fallback.className = 'text-xl font-bold text-primary';
            fallback.textContent = 'ADRIA';
            e.currentTarget.parentElement?.appendChild(fallback);
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-4">
        <Select value={filteredTechnology} onValueChange={onFilterChange}>
          <SelectTrigger className="w-[180px] bg-white">
            <Filter className="w-4 h-4 mr-2 text-foreground" />
            <SelectValue placeholder="Filtrer par technologie" className="text-foreground" />
          </SelectTrigger>
          <SelectContent>
            {technologies.map((tech) => (
              <SelectItem key={tech} value={tech} className="text-foreground">
                {tech === 'all' ? 'Toutes les technologies' : tech}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="icon"
            onClick={() => onNavigate('prev')}
            className="bg-white text-foreground hover:text-foreground hover:bg-secondary"
          >
            ←
          </Button>
          <div className="flex items-center gap-2 px-4 py-2 bg-white rounded-md shadow-sm">
            <Calendar className="w-4 h-4 text-foreground" />
            <span className="font-medium text-foreground">
              {formatDate(currentDate, viewMode)}
            </span>
          </div>
          <Button
            variant="outline"
            size="icon"
            onClick={() => onNavigate('next')}
            className="bg-white text-foreground hover:text-foreground hover:bg-secondary"
          >
            →
          </Button>
        </div>

        <div className="flex items-center gap-2">
          {(Object.keys(viewModeLabels) as ViewMode[]).map((mode) => (
            <Button
              key={mode}
              variant="outline"
              size="sm"
              onClick={() => onViewModeChange(mode)}
              className={cn(
                "bg-white text-foreground hover:text-foreground hover:bg-secondary",
                viewMode === mode && "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground"
              )}
            >
              {viewModeLabels[mode]}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}
import { supabase } from '../supabase';
import { UserProfile } from '@/types/auth';

export async function getUserByEmail(email: string) {
  const { data: user, error } = await supabase
    .from('users')
    .select()
    .eq('email', email)
    .single();

  if (error && error.code !== 'PGRST116') {
    throw error;
  }

  return user as UserProfile | null;
}

export async function createUser(userData: {
  email: string;
  display_name: string;
  first_name: string;
  last_name: string;
  session_token: string;
}) {
  const { data: user, error } = await supabase
    .from('users')
    .insert(userData)
    .select()
    .single();

  if (error) {
    throw error;
  }

  return user as UserProfile;
}
import { useAuth } from '@/contexts/AuthContext';
import { Button } from '@/components/ui/button';
import { motion } from 'framer-motion';

interface LoginFormProps {
  onDebugLog: (type: 'info' | 'error', message: string, data?: any) => void;
}

export function LoginForm({ onDebugLog }: LoginFormProps) {
  const { login, user } = useAuth();

  const handleLogin = async () => {
    try {
      onDebugLog('info', 'Tentative de connexion');
      await login();
      if (user) {
        onDebugLog('info', 'Utilisateur connecté', user);
      }
    } catch (error) {
      onDebugLog('error', 'Erreur de connexion', error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-6"
    >
      <div className="text-center">
        <motion.div
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, type: "spring" }}
        >
          <img 
            src="/orbiq-logo.svg" 
            alt="Orbiq Logo" 
            className="w-[70%] h-auto mx-auto mb-6"
          />
        </motion.div>
        <h2 className="text-3xl font-bold text-foreground">
          Bienvenue sur Orbiq
        </h2>
        <p className="mt-2 text-muted-foreground">
          Connectez-vous avec votre compte professionnel
        </p>
      </div>

      <Button
        onClick={handleLogin}
        className="w-full py-6 bg-[#0078D4] hover:bg-[#106EBE] text-white flex items-center justify-center gap-3"
      >
        <svg className="w-6 h-6" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1h10v10H1z" fill="#F25022"/>
          <path d="M12 1h10v10H12z" fill="#7FBA00"/>
          <path d="M1 12h10v10H1z" fill="#00A4EF"/>
          <path d="M12 12h10v10H12z" fill="#FFB900"/>
        </svg>
        Se connecter avec Microsoft
      </Button>

      {process.env.NODE_ENV === 'development' && (
        <Button
          variant="outline"
          onClick={() => {
            onDebugLog('info', 'Accès direct au tableau de bord (mode développement)');
            window.location.href = '/dashboard';
          }}
          className="w-full"
        >
          Accéder directement au tableau de bord
        </Button>
      )}
    </motion.div>
  );
}
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '@/components/layout/Sidebar';
import { Footer } from '@/components/layout/footer';
import { Toaster } from '@/components/ui/toaster';

export function DashboardLayout() {
  const [currentView, setCurrentView] = useState<'dashboard' | 'stats' | 'gantt'>('dashboard');

  return (
    <div className="flex h-screen bg-background">
      <Sidebar 
        className="w-64 hidden md:block" 
        currentView={currentView} 
        onViewChange={setCurrentView}
      />
      
      <div className="flex-1 flex flex-col min-h-screen">
        <main className="flex-1 overflow-y-auto p-8">
          <Outlet />
        </main>
        <Footer />
      </div>
      
      <Toaster />
    </div>
  );
}
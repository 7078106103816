import { format } from 'date-fns';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';
import { ProjectWithDates } from './types';

interface ProjectDialogProps {
  project: ProjectWithDates | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function ProjectDialog({ project, open, onOpenChange }: ProjectDialogProps) {
  const getProjectColor = (urgencyLevel: number) => {
    switch (urgencyLevel) {
      case 5: return 'bg-red-500';
      case 4: return 'bg-orange-500';
      case 3: return 'bg-yellow-500';
      case 2: return 'bg-blue-500';
      default: return 'bg-green-500';
    }
  };

  if (!project) return null;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-foreground">
            <div className={cn("w-3 h-3 rounded-full", getProjectColor(project.urgencyLevel))} />
            {project.name}
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <h4 className="font-medium mb-2 text-foreground">Description</h4>
            <p className="text-sm text-muted-foreground">{project.description}</p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="font-medium mb-2 text-foreground">Chef de projet</h4>
              <p className="text-sm text-foreground">{project.projectManager}</p>
            </div>
            <div>
              <h4 className="font-medium mb-2 text-foreground">Technologie</h4>
              <Badge>{project.technology}</Badge>
            </div>
          </div>
          <div>
            <h4 className="font-medium mb-2 text-foreground">Équipe</h4>
            <div className="flex flex-wrap gap-2">
              {project.team.map(member => (
                <Badge key={member.id} variant="outline">
                  {member.name} ({member.role})
                </Badge>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="font-medium mb-2 text-foreground">Charge</h4>
              <p className="text-sm text-foreground">
                {project.workload.consumed}j / {project.workload.estimated}j
              </p>
            </div>
            <div>
              <h4 className="font-medium mb-2 text-foreground">Progression</h4>
              <p className="text-sm text-foreground">{Math.round(project.progress)}%</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="font-medium mb-2 text-foreground">Date de début</h4>
              <p className="text-sm text-foreground">{format(project.startDate, 'dd/MM/yyyy')}</p>
            </div>
            <div>
              <h4 className="font-medium mb-2 text-foreground">Date de fin</h4>
              <p className="text-sm text-foreground">{format(project.endDate, 'dd/MM/yyyy')}</p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
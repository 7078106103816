import { DashboardLayout } from '@/layouts/DashboardLayout';
import { DashboardPage } from '@/pages/dashboard/DashboardPage';
import { ProjectsPage } from '@/pages/dashboard/ProjectsPage';
import { StatisticsPage } from '@/pages/dashboard/StatisticsPage';
import { GanttPage } from '@/pages/dashboard/GanttPage';
import { ProtectedRoute } from '@/components/auth/ProtectedRoute';
import { ROUTES } from '@/lib/constants';

export const dashboardRoutes = [
  {
    path: ROUTES.DASHBOARD.HOME,
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />
      },
      {
        path: 'projects',
        element: <ProjectsPage />
      },
      {
        path: 'statistics',
        element: <StatisticsPage />
      },
      {
        path: 'gantt',
        element: <GanttPage />
      }
    ]
  }
];
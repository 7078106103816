import { useState } from 'react';
import { Card } from '@/components/ui/card';
import {
  format,
  addMonths,
  subMonths,
  addWeeks,
  subWeeks,
  addYears,
  subYears,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  eachDayOfInterval,
  subDays,
  isSameMonth
} from 'date-fns';
import { Project } from '@/types/project';
import { ViewMode, ProjectWithDates } from './gantt/types';
import { GanttHeader } from './gantt/GanttHeader';
import { GanttGrid } from './gantt/GanttGrid';
import { GanttStats } from './gantt/GanttStats';
import { ProjectDialog } from './gantt/ProjectDialog';

interface GanttViewProps {
  projects: Project[];
}

export function GanttView({ projects }: GanttViewProps) {
  const [viewMode, setViewMode] = useState<ViewMode>('Month');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [filteredTechnology, setFilteredTechnology] = useState<string>('all');
  const [selectedProject, setSelectedProject] = useState<ProjectWithDates | null>(null);
  const [showDialog, setShowDialog] = useState(false);

  const technologies = ['all', ...new Set(projects.map(p => p.technology))];

  const getDateRange = () => {
    switch (viewMode) {
      case 'Year':
        return {
          start: startOfYear(currentDate),
          end: endOfYear(currentDate),
        };
      case 'Quarter':
        return {
          start: startOfQuarter(currentDate),
          end: endOfQuarter(currentDate),
        };
      case 'Month':
        return {
          start: startOfMonth(currentDate),
          end: endOfMonth(currentDate),
        };
      case 'Week':
        return {
          start: startOfWeek(currentDate, { weekStartsOn: 1 }),
          end: endOfWeek(currentDate, { weekStartsOn: 1 }),
        };
    }
  };

  const navigate = (direction: 'prev' | 'next') => {
    switch (viewMode) {
      case 'Year':
        setCurrentDate(prev => direction === 'next' ? addYears(prev, 1) : subYears(prev, 1));
        break;
      case 'Quarter':
        setCurrentDate(prev => direction === 'next' ? addMonths(prev, 3) : subMonths(prev, 3));
        break;
      case 'Month':
        setCurrentDate(prev => direction === 'next' ? addMonths(prev, 1) : subMonths(prev, 1));
        break;
      case 'Week':
        setCurrentDate(prev => direction === 'next' ? addWeeks(prev, 1) : subWeeks(prev, 1));
        break;
    }
  };

  const processProjects = (): ProjectWithDates[] => {
    return projects.map(project => {
      const [day, month, year] = project.deadline.split('/').map(Number);
      const endDate = new Date(year, month - 1, day);
      const duration = Math.ceil(project.workload.estimated);
      const startDate = subDays(endDate, duration);

      const dateRange = getDateRange();
      const isVisible = (
        (startDate <= dateRange.end && endDate >= dateRange.start) &&
        (filteredTechnology === 'all' || project.technology === filteredTechnology)
      );

      return {
        ...project,
        startDate,
        endDate,
        isVisible
      };
    });
  };

  const dateRange = getDateRange();
  const daysToShow = eachDayOfInterval({ start: dateRange.start, end: dateRange.end });
  const processedProjects = processProjects();

  return (
    <div className="container mx-auto py-8 px-4">
      <div className="flex flex-col gap-6">
        <GanttHeader
          viewMode={viewMode}
          currentDate={currentDate}
          technologies={technologies}
          filteredTechnology={filteredTechnology}
          onViewModeChange={setViewMode}
          onFilterChange={setFilteredTechnology}
          onNavigate={navigate}
        />

        <Card className="p-6 overflow-x-auto">
          <GanttGrid
            daysToShow={daysToShow}
            currentDate={currentDate}
            projects={processedProjects}
            viewMode={viewMode}
            onProjectClick={(project) => {
              setSelectedProject(project);
              setShowDialog(true);
            }}
          />
        </Card>

        <GanttStats
          projects={processedProjects}
          technologies={technologies}
        />

        <ProjectDialog
          project={selectedProject}
          open={showDialog}
          onOpenChange={setShowDialog}
        />
      </div>
    </div>
  );
}
import { motion } from 'framer-motion';

export function Footer() {
  return (
    <footer className="border-t bg-white/50 backdrop-blur-sm">
      <div className="container mx-auto py-4">
        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, type: "spring" }}
          >
            <img 
              src="/orbiq-logo.svg" 
              alt="Orbiq" 
              className="h-16 w-auto"
            />
          </motion.div>
          <div className="flex items-center gap-4 text-sm text-muted-foreground">
            <span>© {new Date().getFullYear()} Orbiq. Tous droits réservés.</span>
            <div className="h-4 w-px bg-border" />
            <a href="#" className="hover:text-foreground transition-colors">
              Politique de confidentialité
            </a>
            <a href="#" className="hover:text-foreground transition-colors">
              Conditions d'utilisation
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
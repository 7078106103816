import { useState } from 'react';
import { Bug } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

interface DebugInfo {
  timestamp: string;
  type: 'info' | 'error';
  message: string;
  data?: any;
}

interface DebugDialogProps {
  logs: DebugInfo[];
}

export function DebugDialog({ logs }: DebugDialogProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm" className="w-full">
          <Bug className="w-4 h-4 mr-2" />
          Afficher les logs de debug
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Logs de débogage</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          {logs.map((log, index) => (
            <div
              key={index}
              className={`p-4 rounded-lg ${
                log.type === 'error' ? 'bg-destructive/10' : 'bg-primary/10'
              }`}
            >
              <div className="flex justify-between text-sm text-muted-foreground">
                <span>{new Date(log.timestamp).toLocaleTimeString()}</span>
                <span className={log.type === 'error' ? 'text-destructive' : 'text-primary'}>
                  {log.type.toUpperCase()}
                </span>
              </div>
              <p className="mt-1 font-medium">{log.message}</p>
              {log.data && (
                <pre className="mt-2 p-2 bg-background rounded text-sm overflow-x-auto">
                  {JSON.stringify(log.data, null, 2)}
                </pre>
              )}
            </div>
          ))}
          {logs.length === 0 && (
            <p className="text-center text-muted-foreground">Aucun log disponible</p>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
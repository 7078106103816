import { format, differenceInDays, startOfDay } from 'date-fns';
import { cn } from '@/lib/utils';
import { ProjectWithDates, ViewMode } from './types';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { calculateGridPosition } from '@/lib/date-utils';

interface GanttRowProps {
  project: ProjectWithDates;
  daysToShow: Date[];
  viewMode: ViewMode;
  onClick: () => void;
}

export function GanttRow({ project, daysToShow, viewMode, onClick }: GanttRowProps) {
  const getProjectColor = (urgencyLevel: number, isVisible: boolean) => {
    if (!isVisible) return 'bg-gray-300 hover:bg-gray-400';
    
    switch (urgencyLevel) {
      case 5: return 'bg-red-500 hover:bg-red-600';
      case 4: return 'bg-orange-500 hover:bg-orange-600';
      case 3: return 'bg-yellow-500 hover:bg-yellow-600';
      case 2: return 'bg-blue-500 hover:bg-blue-600';
      default: return 'bg-green-500 hover:bg-green-600';
    }
  };

  const calculatePosition = () => {
    const firstDay = startOfDay(daysToShow[0]);
    const lastDay = startOfDay(daysToShow[daysToShow.length - 1]);
    
    // Handle projects without dates
    if (!project.endDate) {
      // Show as a single day marker at the start of the period
      return {
        left: '0%',
        width: '2%', // Small width to show as a marker
        isMarker: true
      };
    }
    
    // Ensure dates are within the visible range
    const visibleStart = project.startDate < firstDay ? firstDay : project.startDate;
    const visibleEnd = project.endDate > lastDay ? lastDay : project.endDate;
    
    const startPosition = calculateGridPosition(visibleStart, firstDay, lastDay);
    const endPosition = calculateGridPosition(visibleEnd, firstDay, lastDay);
    const width = endPosition - startPosition;

    return {
      left: `${startPosition}%`,
      width: `${width}%`,
      isMarker: false
    };
  };

  const formatDuration = () => {
    if (!project.endDate) return 'Date non définie';
    const duration = differenceInDays(project.endDate, project.startDate) + 1;
    switch (viewMode) {
      case 'Year':
        return `${Math.round(duration / 30)} mois`;
      case 'Quarter':
        return `${Math.round(duration / 7)} semaines`;
      default:
        return `${duration} jours`;
    }
  };

  const position = calculatePosition();

  return (
    <div className="grid grid-cols-[250px_1fr] gap-4">
      <div className="sticky left-0 z-10 flex items-center gap-2 px-4 py-2 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className={cn(
          "w-3 h-3 rounded-full",
          getProjectColor(project.urgencyLevel, project.isVisible)
        )} />
        <div className="flex flex-col min-w-0">
          <span className={cn(
            "font-medium truncate",
            project.isVisible ? "text-foreground" : "text-muted-foreground"
          )}>
            {project.name}
          </span>
          <span className="text-sm text-muted-foreground truncate">
            {project.projectManager}
          </span>
        </div>
      </div>
      <div className="relative h-12">
        {project.isVisible ? (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  className={cn(
                    "absolute h-full cursor-pointer transition-all hover:opacity-100",
                    getProjectColor(project.urgencyLevel, true),
                    position.isMarker ? (
                      "w-3 rounded-full opacity-75 hover:scale-110"
                    ) : (
                      "rounded-md opacity-90"
                    )
                  )}
                  style={{
                    left: position.left,
                    width: position.isMarker ? undefined : position.width
                  }}
                  onClick={onClick}
                >
                  {!position.isMarker && (
                    <div className="px-2 py-1 text-xs text-white font-medium truncate">
                      {Math.round(project.progress)}%
                    </div>
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="max-w-xs space-y-1">
                  <p className="font-medium">{project.name}</p>
                  <p className="text-sm text-muted-foreground">{project.description}</p>
                  <div className="mt-2 text-xs space-y-1">
                    <p>Début: {project.startDate ? format(project.startDate, 'dd/MM/yyyy') : 'Non défini'}</p>
                    <p>Fin: {project.endDate ? format(project.endDate, 'dd/MM/yyyy') : 'Non défini'}</p>
                    <p>Durée: {formatDuration()}</p>
                    <p>Progression: {Math.round(project.progress)}%</p>
                  </div>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <div className="h-full flex items-center justify-center text-sm text-muted-foreground">
            Hors période
          </div>
        )}
      </div>
    </div>
  );
}
export const APP_NAME = 'Orbiq';
export const APP_DESCRIPTION = 'Gestion de projets simplifiée';

export const ROUTES = {
  HOME: '/',
  AUTH: {
    LOGIN: '/auth/login',
    CALLBACK: '/auth/callback'
  },
  DASHBOARD: {
    HOME: '/dashboard',
    PROJECTS: '/dashboard/projects',
    STATISTICS: '/dashboard/statistics',
    GANTT: '/dashboard/gantt'
  }
} as const;

export const SUPPORTED_TECHNOLOGIES = ['Android', 'Flutter', 'iOS'] as const;

export const ERROR_MESSAGES = {
  AUTH: {
    INVALID_DOMAIN: 'Seuls les emails @adria-bt.com sont autorisés',
    LOGIN_FAILED: 'Échec de la connexion. Veuillez réessayer.',
    SESSION_EXPIRED: 'Votre session a expiré. Veuillez vous reconnecter.'
  },
  PROJECTS: {
    LOAD_ERROR: 'Impossible de charger les projets. Veuillez réessayer plus tard.',
    CREATE_ERROR: 'Impossible de créer le projet. Veuillez réessayer.',
    UPDATE_ERROR: 'Impossible de mettre à jour le projet. Veuillez réessayer.',
    DELETE_ERROR: 'Impossible de supprimer le projet. Veuillez réessayer.'
  }
} as const;
import { XAxisProps, YAxisProps, TooltipProps } from 'recharts';

// Base configuration for axes
const baseAxisConfig = {
  fontSize: 12,
  tickLine: false,
  axisLine: { strokeWidth: 1 },
  tick: { fill: 'hsl(var(--foreground))' }
} as const;

// Default props to prevent Recharts warnings
const defaultAxisProps = {
  allowDecimals: true,
  allowDataOverflow: false,
  allowDuplicatedCategory: true,
  hide: false,
  mirror: false,
  reversed: false,
  scale: 'auto',
  padding: { left: 0, right: 0 }
} as const;

// Tooltip configuration
const tooltipConfig = {
  contentStyle: {
    backgroundColor: 'hsl(var(--background))',
    border: '1px solid hsl(var(--border))',
    borderRadius: '8px',
    padding: '8px 12px'
  },
  itemStyle: { color: 'hsl(var(--foreground))' },
  labelStyle: { color: 'hsl(var(--foreground))' }
} as const;

// Chart colors
export const chartColors = [
  'hsl(var(--chart-1))',
  'hsl(var(--chart-2))',
  'hsl(var(--chart-3))'
] as const;

export function createXAxisProps<T>(props: Partial<XAxisProps<T>> = {}): XAxisProps<T> {
  return {
    ...baseAxisConfig,
    ...defaultAxisProps,
    xAxisId: 0,
    ...props
  };
}

export function createYAxisProps<T>(props: Partial<YAxisProps<T>> = {}): YAxisProps<T> {
  return {
    ...baseAxisConfig,
    ...defaultAxisProps,
    yAxisId: 0,
    ...props
  };
}

export function createTooltipProps<T>(props: Partial<TooltipProps<T, any>> = {}): TooltipProps<T, any> {
  return {
    ...tooltipConfig,
    ...props
  };
}
import { useProjects } from '@/hooks/use-projects';
import { Dashboard } from '@/components/Dashboard';

export function ProjectsPage() {
  const { projects, loading, error, refreshProjects } = useProjects();

  return (
    <Dashboard 
      projects={projects}
      loading={loading}
      error={error}
      onRefresh={refreshProjects}
    />
  );
}